
@import 'varriable.scss';
@import 'mixins.scss';
@import 'extend';
@import 'responsive.scss';
@import 'theme-default.scss';
@import 'btn';
@import 'section_title';
@import 'slick-nav.scss';



// header
@import 'header';

// slider
@import 'slider';

// about
@import 'features';

// counter
@import 'counter';
// counter
@import 'about';

// gallery
@import '_gallery';

// about
@import 'footer';

@import 'bradcam';

@import 'department';

@import 'tesmonial';

@import 'discuss';

@import 'service';

@import 'video';

@import 'portfolio';

@import 'experts';

@import 'emergency_contact';

@import 'prising';

@import '_team';

@import 'getIn_tauch';

@import 'work';

@import 'mission';

@import 'portfolio_details';


// other pages default

// contact
@import 'contact';

// elements
@import 'elements.scss';

// blog
@import 'blog';


// blog part
@import 'blog_part';

