

// slick-nav
.mobile_menu{
    @media #{$mobile_device} {
        position: absolute;
        right: 0px;
        width: 100%;
        z-index: 9;
    }
}
.slicknav_menu{
    .slicknav_nav {
        background: #fff;
        float: right;
        margin-top: 0;
        padding: 0;
        width: 95%;
        padding: 0;
        border-radius: 0px;
        margin-top: 5px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 11px;
        a{
            &:hover{
                background: transparent;
                color: #5DB2FF;
            }
            &.active{
                color: #5DB2FF;
            }
            i{
                @media #{$mobile_device} {
                    display: none;
                }
                @media #{$tablet_device} {
                    display: none;
                }
            }
        }
        .slicknav_btn {
            background-color: transparent;
            cursor: pointer;
            margin-bottom: 10px;
            margin-top: -40px;
            position: relative;
            z-index: 99;
            border: 1px solid #ddd;
            top: 3px;
            right: 5px;
            top: -36px;
            .slicknav_icon{
                margin-right: 6px;
                margin-top: 3px;
                position: relative;
                padding-bottom: 3px;
                top: -11px;
                right: -5px;
            }
        }
    }
    @media #{$mobile_device} {
        margin-right: 0px;
    }
}


// slick-nav
.slicknav_nav .slicknav_arrow {
    float: right;
    font-size: 22px;
    position: relative;
    top: -9px;  
}
.slicknav_btn {
	background-color: transparent;
	cursor: pointer;
	margin-bottom: 10px;
	position: relative;
	z-index: 99;
	border: none;
	border-radius: 3px;
	top: 5px;
	padding: 5px;
	right: 0;
	margin-top: -5px;
    top: -29px;
    right: 5px;

}