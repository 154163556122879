.about_area{
    background: #1F1F1F;
    position: relative;
    &::before{
        content: '';
        background-image: url(../img/about/about.png);
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        z-index: 9;
        background-size: cover;
        position: absolute;
        background-size: cover;
        background-position: center center;
        @media #{$mobile_device} {
            display: none;
        }
        @media #{$tablet_device} {
            display: none;
        }
    }
    .about_thumb{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        img{
            width: 100%;
        }
    }
    .about_info{
        padding: 160px 0;
        @media #{$mobile_device}{
            padding: 80px 0;
        }
        @media #{$tablet_device}{
            padding: 100px 0;
        }
        @media #{$mid_device}{
            padding: 100px 0;
        }
    }
    .section_title{
        h3{
            margin-top: 25px;
            margin-bottom: 54px;
            @media #{$mid_device} {
                font-size: 35px;
                margin-bottom: 20px;
            }
            @media #{$large_device} {
                font-size: 35px;
                margin-bottom: 15px;
            }
        }
        .mid_text{
            color: #C9C9C9;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 14px;
        }
        .last_text{
            color: #C9C9C9;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 44px;
        }
    }
}
