@import url('https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

// fonts
$font1:  'Muli', sans-serif;
$font2:  'Roboto', sans-serif;
// fonts-size

$heading-color:#1F1F1F;
$gray-color: #bebebe;
$gray-color-2: #bdbdbd;

$theme-color: #1F1F1F;
$theme-color2: #ff5e13;

$gray-color3:#5c5c5c;
$white_color:#fff;



$font_1: #666666;
$font_2: #646464;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$font_7: #5c5c5c;
$border_color: #fdcb9e;
$footer_bg: #303030;
$sidebar_bg: #fbf9ff;

$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$tab:'(max-width: 991px)';
$small_mobile:'(max-width: 576px)';
$xs_mobile:'(max-width: 420px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: 'only screen and (min-width: 1200px) and (max-width: 3640px)';

// ,,,,,,,,,,,
$btn_bg: #615CFD;
$btn_hover: #f5790b;
$section_bg: #f7f7f7;
$section_bg_1: #454545;
$heading_color: #191d34;
$heading_color2: #ff8b23;