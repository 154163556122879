.video_area{
    padding-bottom: 60px;
    padding-top: 150px;
    @media #{$mobile_device}{
        padding-top: 50px;
    }
    .video_wrap{
        .thumb{
            margin-bottom: 70px;
            position: relative;
            img{
                width: 100%;
            }
            .video_icon a {
                width: 90px;
                height: 90px;
                background: #615CFD;
                display: inline-block;
                text-align: center;
                line-height: 90px;
                border-radius: 50%;
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%,-50%));
                @media #{$mobile_device} {
                    width: 50px;
                    height: 50px;
                    line-height: 47px;
                }
                i{
                    font-size: 16px;
                    @media #{$mobile_device} {
                        font-size: 13px;
                    }
                }
            
            }
        }
        .video_text{
            p{
                font-size: 30px;
                font-weight: 600;
                line-height: 42px;
                color: #001D38;
                @media #{$mobile_device} {
                    font-size: 18px;
                    line-height: 25px;
                }
                br{
                    @media (min-width: 320px) and (max-width: 991px) {
                        display: none;
                    }
                }
            }
        }
    }   
}