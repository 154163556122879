.bradcam_bg_1{
    background-image: url(../img/banner/bradcam.png);
}
.breadcam_bg_2{
    background-image: url(../img/banner/bradcam_2.png);
}
.breadcam_bg_3{
    background-image: url(../img/banner/bradcam_3.png);
}
.breadcam_bg_4{
    background-image: url(../img/banner/bradcam_4.png);
}
.bradcam_area{
    
    background-size: cover;
    background-position: bottom;
    padding: 214px 0 92px 0;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    &::before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #1F1F1F;
        opacity: .5;
        z-index: -1;
        content: '';
    }
    @media #{$mobile_device} {
        padding: 140px 0 90px 0;
    }
    h3{
        font-size: 78px;
        color: #fff;
        font-weight: 400;
        margin-bottom: 0;
        text-transform: capitalize;
        font-family: $font2;
        @media #{$mobile_device} {
            font-size: 30px;
        }
    }
    p{
        font-size: 18px;
        color: #fff;
        font-weight: 400;
        text-transform: capitalize;
        a{
            color: #fff;
            &:hover{
                color: #fff;
            }
        }
    }
}

.popup_box{
    background: #fff;
    display: inline-block;
    z-index: 9;
    width: 558px;
    // padding: 60px 40px;

    .boxed-btn3{
        width: 100%;
        text-transform: capitalize;
    }
    .popup_header {
        background: #F5FBFF;
        padding: 28px 0;
        display: block;
        h3{
            text-align: center;
            font-size: 20px;
            color:#2C2C2C;
            margin-bottom: 0;
            font-weight: 400;
        }
    }
.custom_form{
    padding: 60px 68px;

}
    input{
        width: 100%;
        height: 50px;
        border: none;
        border-bottom: 1px solid #C7C7C7;
        padding: 15px 0;
        margin-bottom: 20px;
        &::placeholder{
            color: #919191;
            font-weight: 400;
        }
        &:focus{
            outline: none;
        }
    }
    textarea{
        width: 100%;
        // height: 50px;
        border: none;
        margin-top: 112px;
        border-bottom: 1px solid #C7C7C7;
        padding: 15px 0;
        margin-bottom: 20px;
        height: 55px;
        resize: none;;
        margin-bottom: 40px;
        &::placeholder{
            color: #919191;
            font-weight: 400;
        }
        &:focus{
            outline: none;
        }
    }
    .nice-select {
        -webkit-tap-highlight-color: transparent;
        background-color: #fff;
        /* border-radius: 5px; */
        border: solid 1px #E2E2E2;
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-family: $font1;
        font-weight: normal;
        width: 100% !important;
        /* height: 42px; */
        line-height: 50px;
        outline: none;
        padding-left: 18px;
        padding-right: 30px;
        position: relative;
        text-align: left !important;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        width: auto;
        border-radius: 0;
        margin-bottom: 30px;
        height: 50px !important;
        font-size: 16px;
        font-weight: 400;
        color: #919191;
        &::after {
            content: "\f0d7";
            display: block;
            height: 5px;
            margin-top: -5px;
            pointer-events: none;
            position: absolute;
            right: 17px;
            top: 3px;
            transition: all 0.15s ease-in-out;
            width: 5px;
            font-family: fontawesome;
            color: #919191;
            font-size: 15px;
        }
        &.open .list {
            opacity: 1;
            pointer-events: auto;
            -webkit-transform: scale(1) translateY(0);
            -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
            height: 200px;
            overflow-y: scroll;
        }
        &.list {
            height: 200px;
            overflow-y: scroll;
        }
    }
}
#test-form {
    display: inline-block;
    margin: auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform (translate(-50%,-50%));
    .mfp-close-btn-in .mfp-close {
        color: #333;
        display: none !important;
    }
    button{
        &.mfp-close{
            display: none !important;
        }
    }
}
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #000000;
    opacity: .7;
}