.slider_bg_1{
    background-image: url(../img/banner/banner.png);
}
.slider_bg_2{
    background-image: url(../img/banner/banner2.png);
}
.slider_area{

    .single_slider{
        position: relative;
        z-index: 0;
        height: 900px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        @media #{$mobile_device} {
            height: 500px;
        }
        @media #{$tablet_device} {
            height: 620px;
        }
        &.about_banner.overlay::before{
            opacity: .5;
        }
        .slider_text{
            h3{
                color: #FFF;
                font-family: $font2;
                font-size: 78px;
                font-weight: 400;
                line-height: 84px;
                margin-bottom: 54px;
             span{
                 font-weight: 700;
             }
             @media #{$mobile_device} {
               font-size: 30px;
            //    letter-spacing: 3px;
                line-height: 35px;
            }
             @media #{$tablet_device} {
               font-size: 50px;
               line-height: 62px;

            }
             @media #{$mid_device} {
               font-size: 45px;
               line-height: 55px;

            }
            }
            span{
                font-size: 30px;
                font-weight: 400;
                color: #FFFFFF;
                position: relative;
                z-index: 1;
                &::before{
                    position: absolute;
                    background: #fff;
                    width: 50px;
                    height: 2px;
                    content: '';
                    left: -67px;
                    top: 50%;
                    margin-bottom: -1px;
                }
             @media #{$mid_device} {
                font-size: 16px;
             }
             @media #{$mobile_device} {
                font-size: 16px;
             }
            }
            .video_service_btn> a{
                margin-right: 15px;
                @media #{$mobile_device} {
                    margin-bottom: 20px;
                }
            }
        }
    } 
}