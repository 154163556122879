.section_title{

    span{
        position: relative;
        z-index: 3;
        font-size: 14px;
        font-weight: 500;
        color: #615CFD;
        text-transform: capitalize;
        margin-bottom: 19px;
        display: block;
        font-family: $font2;
    }
    h3{
        font-size: 40px;
        line-height: 52px;
        font-weight: 400 ;
        color: #1F1F1F;
        position: relative;
        z-index: 0;
        padding-bottom: 15px;
        
        @media #{$mobile_device} {
            font-size: 30px;
            line-height: 36px;
        }
        @media #{$tablet_device} {
            font-size: 36px;
            line-height: 42px;
        }
        br{
            @media #{$mobile_device} {
                display: none;
            }
        }
    }
    p{
        font-size: 16px;
        color: #727272;
        line-height: 28px;
        margin-bottom: 0;
        font-weight: 400;
        br{
            @media #{$mobile_device} {
                display: none;
            }
        }

    }
    &.white_text{
        h3{
            color: #fff;
        }
        span{
            color: #fff;
            padding-top: 0;
            &:before{
                display: none;
            }
        }
    }
}
.mb-50{
    margin-bottom: 50px;
    @media #{$mobile_device} {
        margin-bottom: 40px;
    }
}
.mb-90{
    margin-bottom: 90px;
    @media #{$mobile_device} {
        margin-bottom: 40px;
    }
}