.footer{
    background-repeat: no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .footer_top{
        padding-top: 100px;
        padding-bottom: 160px;
        background: #1F1F1F;
        @media #{$mobile_device} {
            padding-top: 50px;
            padding-bottom: 30px;
        }
        @media #{$tablet_device} {
            padding-top: 50px;
            padding-bottom: 60px;
        }
        @media #{$mid_device} {
            padding-top: 100px;
            padding-bottom: 100px;
        }
        .menu_links{
            @media #{$mobile_device} {
                margin-top: 15px;
            }
            ul{
                li{
                    display: inline-block;
                    margin-right: 46px;
                    @media #{$mobile_device} {
                        margin-right: 10px;
                    }
                    @media #{$mid_device} {
                        margin-right: 30px;
                    }
                    @media #{$tablet_device} {
                        margin-right: 30px;
                    }
                    a{
                        color: #fff;
                        font-size: 15px;
                        font-weight: 500;
                        &:hover{
                            color:#615CFD ;
                        }
                    }
                }
            }
        }
    }
    .copy-right_text{
        padding-bottom: 25px;
        background:#1F1F1F;
        .footer_border{
            padding-bottom: 25px;
            border-top: 1px solid #363636;
        }
        .copy_right{
            font-size: 15px;
            color:#C9C9C9;
            margin-bottom: 0;
            font-weight: 400;
            @media #{$mobile_device} {
                font-size: 13px;
            }
            a{
                color: #615CFD;
            }
        }
    }
    .socail_links{
        // margin-top: 47px;
        text-align: right;
        @media #{$mobile_device} {
            // margin-top: 30px;
            text-align: left;
            margin-top: 30px;
        }
        @media #{$tablet_device} {
            // margin-top: 30px;
            text-align: center;
            margin-top: 30px;
        }
        ul{
            li{
                display: inline-block;

                a{
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    text-align: center;
                    background: transparent;
                    @include border-radius(50%);
                    line-height: 40px !important;
                    margin-right: 7px;
                    color: #E7F9F9;
                    line-height: 40px !important;
                    border: 1px solid #E7F9F9;
                    font-size: 14px;
                 &:hover{
                     color: #fff !important;
                     background: #615CFD;
                     border: 1px solid #615CFD;
                 }
                }
            }
        }
    }
}
